.footer-section {
  height: auto;
  padding-bottom: 5%;
  background-color: #D0F4E4;
}
.footer-headings {
  font-weight: 700;
  font-size: 26px;
  line-height: 39px;
  /* color: #0c8b51; */
  color: #101010;
  position: relative;
}

.footer-headings::after {
  content: "";
  position: absolute;
  bottom: -1px; 
  left: 0;
  width: 25%; 
  height: 2px;
  background-color: #E84E36;
}

.footer-section ul li:hover {
  color: #E84E36; /* Change text color on hover */
  cursor: pointer;
}

.footer-image {
  height: 50px;
  margin-bottom: 26px;
}
.footer-section ul {
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: left;
  font-size: 14px;
  line-height: 28px;
  color: #101010;
}
.link-btn {
  color: #101010;
  text-decoration: none;
  font-weight: none !important;
}
.link-btn:hover {
  color: #E84E36;
}
.paragraph-footer {
  line-height: 23px;
  text-align: justify;
  font-size: 14px;
  color: #101010;
  padding-right: 35px;
}

.gapping {
  column-gap: 62px;
}

@media (max-width: 1024px) {
  .gapping {
    column-gap: 52px;
  }
}
@media (max-width: 992px) {
  .footer-section {
    height: auto;
    padding-bottom: 5%;
    text-align: center;
    justify-content: center;
  }
  .gapping {
    column-gap: 0px;
  }
  .footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: #101010;
  }
  .paragraph-footer {
    line-height: 23px;
    text-align: justify;
    font-size: 16px;
    color: #101010;
    padding-right: 0;
  }
}

@media (max-width: 575px) {
  .footer-section {
    height: auto;
    padding-bottom: 5%;
    text-align: center;
    justify-content: center;
  }
  .gapping {
    column-gap: 0px;
  }
  .footer-section ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: center;
    font-size: 16px;
    line-height: 28px;
    color: #101010;
  }
  .paragraph-footer {
    line-height: 23px;
    text-align: justify;
    font-size: 16px;
    color: #101010;
    padding-right: 0;
  }
  
}