.signup-bg {
  background-color: #F3F4F8;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.signUp-container {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
}


.signUp_para {
  font-weight: 700;
  font-size: 10px;
  line-height: 14px;
  color: #333333;
  margin: auto;
  /* margin-right: 59%; */
}