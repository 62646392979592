.claim-discount {
  background: #a578f7;
  /* box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25), -2px -2px 0px rgba(0, 0, 0, 0.25); */
  border-radius: 10px;
}

.claim-offer {
    background: #FBD65A;
    /* box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25), -2px -2px 0px rgba(0, 0, 0, 0.25); */
    border-radius: 10px;
  }
  
  .dashboard-button {
    font-size: 16px;
    display: inline-block;
    padding: 10px 35px 10px 35px;
    border-radius: 10px;
    transition: 0.5s;
    color: #48126A;
    font-weight: 700;
    background: #f3f4f8;
    border: 2px solid #f3f4f8;
    text-decoration: none;
  }
  
  .dashboard-button:hover {
    border-color: #48126A;
    background: #48126A;
    color: white;
  }