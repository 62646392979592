#footer-nav {
    height: auto;
    color: #48126A;
    text-align: left;
}

#footer-nav h5 {
    font-weight: 700;
    font-size: 20px;
    padding-bottom: 1%;
}