.page-video {
  background: #ffffff;
}
.video-frame {
  width: 80%;
}

.video_play {
  width: 100%;
  height: 500px;
  border-radius: 20px;
}

@media (max-width: 992px) {
  .video-frame {
    width: 100%;
  }
  .video_play {
    width: 100%;
    height: 400px;
    border-radius: 10px;
  }
}
@media (max-width: 575px) {
  .video-frame {
    width: 100%;
  }
  .video_play {
    width: 100%;
    height: 300px;
    border-radius: 0;
  }
}