.terminal {
    background-color: #000000;
    height: 45vh;
}

.terminal2 {
    background-color: #000000;
    height: 84vh;
}
.terminal_tab {
    border-bottom: 1px solid rgb(126, 136, 145);
    border-top: 1px solid rgb(126, 136, 145);
    display: flex;
    min-height: 40px;
    white-space: nowrap;
    background-color: #ffffff;
}

.arrow_icon {
    filter: invert(12%) sepia(29%) saturate(6910%) hue-rotate(267deg)
      brightness(90%) contrast(103%);
    height: 25px;
    width: 25px;
}

.terminal_button {
    -webkit-box-align: center;
    align-items: center;
    border-right: 1px solid rgb(126, 136, 145);
    border-left: 1px solid rgb(126, 136, 145);
    cursor: default;
    display: flex;
    font-size: 14px;
    padding: 2px 16px 3px;
    white-space: nowrap;
}

.back_button {
    border-bottom: 1px solid rgb(126, 136, 145);
    min-height: 30px;
    width: 39px;
    background-color: #f3f4f8;
}

.icon_button {
    margin-top: 5px;
    background-color: transparent;
    border: none;
}
/* .icons_section {
    border: none;
    -webkit-box-align: center;
    align-items: center;
} */


