.forgot_bg {
  /* background-image: url("../../../../public/assets/hero.png"); */
  background-color: #F3F4F8;
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.forgot-container {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
}