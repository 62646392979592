.aboutbanner {
    /* height: 100vh; */
    background-color: #f3fafc;
   
  }

  .about-paragraph {
    font-size: 18px;
    line-height: 30px;
  }
  .aboutbanner .content {
    padding-top: 200px;
  
  }
  
  .about-heading {
    font-weight: 800;
    font-size: 36px;
  }
  .sub-title {
    font-weight: 700;
    font-size: 44px !important;
    line-height: 53px !important;
    color: #000000 !important;
  }
  
  .aboutbanner .img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 400px;
    margin-top: 150px;
    margin-bottom: 110px;
  }
  
  .spine2 {
    animation: spine 8s linear infinite;
    -webkit-animation: spine 8s linear infinite;
  }
  @keyframes spine2 {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    from {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
  }
  @-webkit-keyframes spine2 {
    from {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    from {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
  }
  
  .up-down {
    animation: up-down 4s alternate infinite;
    -webkit-animation: up-down 4s alternate infinite;
  }
  .up-down-new {
    animation: up-down 8s alternate infinite;
    -webkit-animation: up-down 8s alternate infinite;
  }
  
  @keyframes up-down {
    0% {
        transform: translateY(30px);
        -webkit-transform: translateY(0);
    }
    50% {
        transform: translateY(-50px);
        -webkit-transform: translateY(-50px);
    }
    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
    }
  }
  @-webkit-keyframes up-down {
    0% {
        transform: translateY(30px);
        -webkit-transform: translateY(0);
    }
    50% {
        transform: translateY(-50px);
        -webkit-transform: translateY(-50px);
    }
    100% {
        transform: translateY(0);
        -webkit-transform: translateY(0);
    }
  }
  
  
  
  
  
  @media (max-width: 1024px) {
    .aboutbanner .content {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (max-width: 992px) {
    .aboutbanner .img {
      min-height: 370px;
    }
    .aboutbanner .content {
      padding-top: 0;
      padding-bottom: 30px;
      text-align: center;
    }
  }
  @media (max-width: 575px) {
    .aboutbanner .img {
      min-height: 300px;
    }
    .aboutbanner .content {
      padding-top: 0;
      padding-bottom: 30px;
      text-align: center;
    }
  }
  
  
  .aboutbanner-intro-box .intro-img {
    position: absolute;
    right: 8%;
    top: 60px;
  }
  .aboutbanner-intro-box .intro-img img {
    width: 600px;
  }
  