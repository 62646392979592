/* .left_side {
  height: auto;
  color: #eee;
  background: #0c8b51;
  text-align: center;
  display: grid;
}
.right_side {
  padding: 40px 40px 40px 40px;
  background: #F9F8F8;
}
.contact-title {
  color: white;
  text-align: center;
}

.contact-form-input {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ffffff;
  margin-bottom: 8px;
  border-radius: 5px;
  margin-top: 8px;
}
.contact-form-input:hover {
  border: 1px solid #0c8b51;
}

.contact_btn {
  width: 100%;
  height: 40px;
  background: #FE6436;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  border: none;
}

.contact_btn:hover {
  width: 100%;
  height: 40px;
  background: #FF4008;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  border: none;
}
.textarea {
  min-height: 100px;
}

.left_side {
  background-color: #F9F8F8; 
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.left_side img {
  width: 100%; 
  height: auto; 
  object-fit: cover; 
}
 */

/* .contact-card {
  margin-top: 15%;
} */
 .contact-details {
  background-color: #F9F8F8; /* Background color for the whole section */
}

.contact-card {
  display: flex;
  align-items: stretch; /* Ensures both sides stretch to the same height */
}

.left_side {
  background-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 100%; /* Ensures left side fills the height */
}

.left_side img {
  width: 100%;
  height: 100%;
  /* object-fit: cover; */
}

.right_side {
  padding: 40px;
  background: #F9F8F8;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contact-form-input {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #ffffff;
  margin-bottom: 8px;
  border-radius: 5px;
  margin-top: 8px;
}

.contact-form-input:hover {
  border: 1px solid #FE6436;
}

.contact_btn {
  width: 100%;
  height: 40px;
  background: #FE6436;
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  border: none;
}

.contact_btn:hover {
  background: #FF4008;
}
