.login-container {
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  opacity: 1;
}
.link-button {
  padding: 10px 20px;
  display: flex;
  align-items: center;
  color: #333;
  text-decoration: none;
  font-size: 20px;
  font-weight: 500;
  color: #48126A !important;
}

.top-left {
  position: absolute;
  top: 20px;
  left: 20px;
}

.login-paragraph {
  font-size: 16px !important;
}
.arrow_icon {
  margin-right: 5px;
}

.login_bg {
  /* background-image: url("../../../../public/assets/hero.png"); */
  background-color: #F3F4F8;
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  width: 100%;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
}

.left-side{
  background-image: url("../../../../public/assets/login_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 100%;
  border-radius: 10px 0px 0px 10px;
  margin-left: -15px;
}
.login_img {
  height: 100%;
  width: 90%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: auto;
  margin-bottom: auto;
}


.auth-passwordfield {
  position: relative;
}
.auth-passwordfield .eyeicon {
  position: absolute;
  right: 10px;
  top: 50%; 
  transform: translateY(-50%);
  cursor: pointer;
  height: 18px;
  /* filter: invert(14%) sepia(44%) saturate(4037%) hue-rotate(265deg) brightness(83%) contrast(105%); */
  filter: invert(53%) sepia(94%) saturate(5446%) hue-rotate(346deg) brightness(101%) contrast(82%);
}