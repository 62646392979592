.main {
    display: flex;
    flex-wrap: wrap;
  }
  
  .main-wrapper {
    flex: 1 0 0%;
    padding: 100px 30px 30px 285px;
    overflow: auto;
    width: 100%;
  }
  
  .d-row {
    flex-direction: row !important;
  }
  
  .siderbar2 {
    position: fixed;
  }
  .courses-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 999;
  }
  .main-wrapper2 {
    flex: 1 0 0%;
  
    overflow: auto;
    width: 100%;
  }

  .main-navbar {
    flex: 1 0 0%;
    overflow: auto;
    width: 100%;
  }