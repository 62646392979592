.ceo-img {
    width: 250px;
    height: 250px;
}


@media (max-width: 1024px) {
    .our-aim p {
        padding-left: 10px;
    }
    .ceo-img {
        width: 200px;
        height: 200px;
    }
  }
  @media (max-width: 992px) {
    .our-aim p {
        
    }
  }
  @media (max-width: 575px) {
    .our-aim p {
        
    }
  }