.enrollment {
  background: linear-gradient(135deg, #E84E36, #FF6A50);
  height: auto;
  border-radius: 10px;
  padding: 15px;
  border: 1px solid rgb(206, 204, 204);
}
.app-head {
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  justify-content: left;
}
.enroll_btn {
  width: 100%;
  height: 40px;
  background: #ffffff;
  border: 1px solid #E84E36;
  font-size: 16px;
  line-height: 30px;
  color: #E84E36;
  border-radius: 10px;
}

.enroll_btn:hover {
  width: 100%;
  height: 40px;
  background: #FF4008;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  border-radius: 10px;
}
.help_btn {
  width: 100%;
  height: 40px;
  background: #E84E36;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  border: 1px solid #E84E36;
  border-radius: 10px;
}

.help_btn:hover {
  width: 100%;
  height: 40px;
  background: #FF4008;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  border: 1px solid #FF4008;
  border-radius: 10px;
}

.enrollment ul {
  list-style: none;
  color: #ffffff;
}
.enroll-img {
  width: 18px;
  height: 18px;
  filter: invert(100%);
}