.white-card {
    background-color: #f3f3f3;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    width: 500px;
  }
  
  .card-content {
    text-align: center;
  }
  
  .card-icon {
    width: 30px;
    height: 30px;
  }
  