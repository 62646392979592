.navbarAvatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  margin-right: 10px;
}

.navbar-brand {
  /* font-weight: 700; */
  font-size: 28px;
  color: white !important;
}
.navbar {
  background-color: transparent;
}

.navbar.colorChange {
  background-color: #ffffff;
  box-shadow: 0 4px 2px -2px rgb(206, 204, 204);
}

@media (max-width: 768px) {
  .navbar-nav {
    margin-left: 0px;
  }
}

ul {
  margin: 0 auto;
}
.nav-link {
  /* font-size: 20px; */
  line-height: 24px;
  text-align: justify;
}
.page-nav-item {
  padding-left: 50px;
}

.nav-link:hover {
  color: #E84E36 !important;
  text-decoration: none;
}


.btn-success {
  background-color: #E84E36;
  border: none;
  width: 100px;
  border-radius: 50px;
}
.btn-outline-info {
  border: 1px solid #E84E36;
  color: #E84E36;
  width: 100px;
  border-radius: 50px;
}

.btn-outline-info:hover {
  background-color: #FF4008;
  border: none;
  color: white;
}

.btn-success:hover {
  background-color: #FF4008;
  border: none;
  color: white;
}

.icon-container img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.icon-container {
  width: 40px;
  height: 40px;
  position: relative;
  display: inline-block;  
  vertical-align: middle; 
  margin-right: 5px;
}

.username {
  vertical-align: middle; 
  font-size: 16px !important;
}

.dropdown-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 5px;
  vertical-align: middle;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid currentColor;
}

.status-circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: green;
  border: 2px solid white;
  position: absolute;
  bottom: 0;
  right: 0;
}

/* .dropdown-menu {
  top: 85% !important; 
  right: 80% !important;
} */

.dropdown-toggle::after {
  content: none !important;
}