body {
  margin: 0;
  /* font-family: CeraPro, Verdana, Sans-Serif; */
  font-family: "Nunito", sans-serif;
    box-sizing: border-box;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* background-color: #f3f4f8; */
  /* background-color: #F7F5FA; */
  background-color: #ffffff;
}

/* Heading Tags */
/* h1 {
  font-weight: 700;
  font-size: 32px;
  line-height: 52px;
  color: #0c8b51;
  text-align: center;
} */


/* h2 {
  font-weight: 700;
  font-size: 22px;
  line-height: 30px;
  color: #0c8b51;
} */

h3 {
  font-size: 18px;
  line-height: 29px;
  color: #000000;
}

h4 {
  font-weight: 700;
  font-size: 32px;
  line-height: 52px;
  color: #0c8b51;
}

h5 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #ffffff;
}
h6 {
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  color: #0c8b51;
}

/* p{
  color: #000000;
  text-align: justify;
} */
.paragraph {
  text-align: justify;
  font-size: 16px;
}

/* Button */
.general-button {
  font-size: 16px;
  display: inline-block;
  padding: 10px 35px 10px 35px;
  border-radius: 10px;
  transition: 0.5s;
  color: #E84E36;
  font-weight: 700;
  background: #f3f4f8;
  border: 2px solid #f3f4f8;
  text-decoration: none;
}

.general-button:hover {
  border-color: #E84E36;
  background: #E84E36;
  color: white;
}

/* Form Inputs  */
.form-input {
  width: 100%;
  height: 40px;
  background: #f3f4f8;
  border: 1px solid #f3f4f8;
  margin-bottom: 8px;
  border-radius: 5px;
  margin-top: 8px;
}
.form-input:focus {
  border: 1px solid #005b96;
}

.placeholder {
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 30px;
  padding-left: 11px;
  padding-right: 11px;
  color: #999999;
}




/*Forms Start */
/* .auth_bg {
  background-image: url("../public/assets/hero.png");
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 1;
  width: 100%;
  height: 100%;
  padding-top: 50px;
  padding-bottom: 15px;
  justify-content: center;
  align-items: center;
  display: flex;
} */

.form_box {
  border-radius: 15px;
  background-color: white;
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25), -2px -2px 0px rgba(0, 0, 0, 0.25);
}

.form_content {
  font-weight: 700;
  text-align: left;
}

/* .form-input {
  width: 100%;
  height: 43px;
  background: white;
  border: 1px solid #000000;
  margin-bottom: 10px;
  margin-top: 10px;
} */

.input_placeholder {
  font-style: normal;
  /* font-weight: 700; */
  font-size: 15px;
  line-height: 30px;
  padding-left: 11px;
  color: #999999;
}

.form_btn {
  width: 100%;
  height: 40px;
  background: #e84e36;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  border: none;
  /* border-radius: 10px; */
}

.form_btn:hover {
  width: 100%;
  height: 40px;
  background: #FF4008;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  color: #ffffff;
  border: none;
  /* border-radius: 10px; */
}
/* Forms End */

.colgap {
  column-gap: 139px;
}

a {
  color: #e84e36;
  text-decoration: none;
}

a:hover {
  color: #FF4008;
}

.sub-paragrapgh {
  font-size: 18px;
  line-height: 28px;
  text-align: center;
  /* color: #000000; */
  padding-left: 100px;
  padding-right: 100px;
}

.color {
  color: #48126A;
}


/* Stripe Form */


form > * {
  margin: 10px 0;
}

#submit {
  background-color: #E84E36;
}

#submit {
  background: #E84E36;
  border-radius: 10px;
  color: white;
  border: 0;
  padding: 10px 16px;
  margin-top: 20px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  display: block;
}
#submit:hover {
  filter: contrast(115%);
}
#submit:active {
  transform: translateY(0px) scale(0.98);
  filter: brightness(0.9);
}
#submit:disabled {
  opacity: 0.5;
  cursor: none;
}

input,
select {
  display: block;
  font-size: 1.1em;
  width: 100%;
  margin-bottom: 10px;
}

label {
  display: block;
}

a {
 color: #e84e36;
  /* font-weight: 900; */
}

small {
  font-size: 0.6em;
}

fieldset,
input,
select {
  border: 1px solid #efefef;
}

#payment-form {
  margin-top: 10px;
}

#messages {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New";
  display: none; /* hide initially, then show once the first message arrives */
  background-color: #0a253c;
  color: #00d924;
  padding: 20px;
  margin: 20px 0;
  border-radius: 10px;
  font-size: 0.7em;
}
