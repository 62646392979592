.sub-title {
    font-weight: 700;
    font-size: 44px !important;
    line-height: 53px !important;
    color: #000000 !important;
}
  
  .contact-banner .img {
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 600px;
    margin-top: 90px;
  }
  
  @media (max-width: 1024px) {
    .contact-banner .content {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (max-width: 992px) {
    .contact-banner .img {
      min-height: 370px;
    }
    .contact-banner .content {
      padding-top: 0;
      padding-bottom: 30px;
      text-align: center;
    }
  }
  @media (max-width: 575px) {
    .contact-banner .img {
      min-height: 300px;
    }
    .contact-banner .content {
      padding-top: 0;
      padding-bottom: 30px;
      text-align: center;
    }
  }
  