.quiz-container {
    background-color: #ffffff;
    height: auto;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25), -2px -2px 0px rgba(0, 0, 0, 0.25);
}

.quiz-container ul li {
    text-decoration: none;
    list-style: none;
    color: #000000;
    font-size: 16px;
    background: #ffffff;
    border: 1px solid #eaeaea;
    border-radius: 16px;
    padding: 11px;
    margin-top: 15px;
    cursor: pointer;
}
  
.quiz-container ul li:hover {
    background: #F3F4F8;
    color: rgb(0, 0, 0);
}

.answer-label {
    padding: 0;
    font-size: 20px;
    font-weight: 500;
}

.quiz-container ul .selected-answer {
    background: rgba(232, 78, 54, 0.5);
    border: 1px solid #e84e36;
    color: white;
}
  
.quiz-container ul li.correct {
    background-color: rgb(125, 235, 125);
    border: 1px solid rgb(53, 212, 53);;
    color: white;
}
  
.quiz-container ul li.incorret {
    background: rgba(232, 78, 54, 0.5);
    border: 1px solid #e84e36;
    color: white;
}

.answerlist {
    white-space: pre-line;
    padding-left: 10px;
}

pre code {
    padding-top: 20px;
    padding-left: 80px;
    display:block;
}

.fib-answer-explanation {
    margin-top: 5px;
    margin-left: 120px;
}

.quiz-button {
    background-color: #324DC7;
    border-radius: 50px;
    font-size: 18px;
    color: white;
    padding: 10px 20px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    margin: 4px 2px;
    cursor: pointer;
    outline: none;
    border: none;
    /* margin-top: 15px; */
}
  
.quiz-button:disabled {
    background: #e7e8e9;
    color: #9fa3a9;
    cursor: not-allowed;
}
  
.flex-right {
    display: flex;
    justify-content: flex-end;
}

.quiz_video_frame {
    height: 500px;
    width: 100%;
    border-radius: 10px;
}