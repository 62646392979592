.programming-padding {
    padding: 30px 0px 0px 80px;
}
.programming {
    /* height: 100vh; */
    display:grid;
    margin-top: 40px;
	grid-template-areas:
		'question terminal';
	grid-template-columns: min-content 1fr;
    grid-template-rows: min-content 1fr;	
    
}
.question {
    grid-area:question;
    resize:horizontal;
    overflow:auto;
    min-width:30vw;
    max-width:80vw;
    padding-right: 20px;
    padding-left: 20px;
    width:50vw;
    max-height: 100%;

}


.right-side {
    display: grid;
    /* grid-template-areas:
    'editor console'; */
    grid-area:terminal;
    grid-template-rows: 1fr min-content;	
    overflow:auto;
    min-height: 89vh;
    /* max-height: 100%;
    min-height:100px; */
}

.editor{
	background-color: #333333;
    grid-area:editor;
    resize:vertical;
    overflow:auto;
    height:45vh;
    min-height: 25vh;
    /* min-height:20px; */
    /* max-height: 40vh; */
}
.console2 {
	background-color: #666666;
    grid-area:console;
    overflow:auto;
    height:44vh;
    min-height: 44vh;
    /* min-height:20px; */

}