.top-navigation {
  display: flex;
  align-items: center;
}

.timer-section {
  background-color: #E6EDF8;
}
.timer-section h6{
  color: #0d0d0d;
}
.timer {
  /* text-align: center; */
  color: #0d0d0d;
  margin-top: 21px;
  font-size: 25px;
}

.sat-quiz-container {
  background-color: #ffffff;
  height: 450px;
  /* border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25), -2px -2px 0px rgba(0, 0, 0, 0.25); */
  border-top: 2px dashed black;
  /* border-bottom: 2px dashed black; */
  display: flex;
}

.quiz-container-sat {
  background-color: #f3f3f3;
  height: auto;
  border-top: 2px dashed black;
  /* border-bottom: 2px dashed black; */
}

.box-answer {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px auto;
  width: 70%;
  border-radius: 10px;
}

.quiz-container-sat .answer-box {
  display: flex;
  flex-wrap: wrap; /* Allow flex items to wrap to the next line */
  padding-top: 30px;
  justify-content: flex-start; /* Align items to the start of the container */
}

.quiz-container-sat .answered-question,
.quiz-container-sat .unanswered-question {
  /* Adjust margin to create spacing between items */
  margin-right: 10px;
  margin-bottom: 10px;
}

.quiz-container-sat .answered-question {
  background-color: blue;
  color: #fff;
  padding: 10px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 1.1;
  border-radius: 5px;
}

/* Style for unanswered questions */
.quiz-container-sat .unanswered-question {
  color: #333;
  border: 1px dashed #333;
  padding: 10px;
  margin-right: 10px;
  width: 40px;
  height: 40px;
  text-align: center;
  line-height: 1.1;
  border-radius: 5px;
}

.unanswered-question-box {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px dashed #333;
  margin-right: 10px;
}
.review-question-box {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.text-inline {
  display: inline-block; /* Make the paragraph an inline-block element */
  vertical-align: middle; /* Align the text vertically in the middle of the line */
}

.result-container {
  margin-top: 60px;
  background-color: #ffffff;
  height: 450px;
  border-top: 2px dashed black;
  /* border-bottom: 2px dashed black; */
}
.question-section {
  border-right: 4px solid #ccc;
  padding-right: 15px;
  height: 100%;
  text-align: justify;
  overflow-y: auto;
}

.description {
  padding-top: 20px;
  padding-right: 70px;
  padding-left: 70px;
}
.answer-section {
  height: 100%;
  overflow-y: auto;
}


/* .mcq-answer-section {
  display: flex;
  flex-direction: column;
}

.mcq-answer-section p {
  margin: 0;
  display: flex;
  align-items: center;
  border-radius: 10px;
}

.mcq-answer-section span {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: transparent;
  color: #0d0d0d;
  text-align: center;
  line-height: 24px;
  margin-right: 15px;
  border: 1px solid #0d0d0d;
}

.mcq-answer-section p {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
} */

/* .mcq-answer-section:hover p,
.mcq-answer-section:hover span {
  border: 1px solid blue;
}

.mcq-answer-section:hover span {
  background-color: blue;
  color: white;
} */

.mcq-answer-section {
  display: flex;
  flex-direction: column;
}

.mcq-choice {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  cursor: pointer;
}


.mcq-choice-letter {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: transparent;
  color: #0d0d0d;
  text-align: center;
  line-height: 24px;
  margin-right: 15px;
  border: 1px solid #0d0d0d;
}


.question-number {
  background-color: #F0F0F0;
  border-bottom: 2px dashed black;
}

.q-number {
  background-color: black;
  color: white;
  width: 30px;
  height: 30px;
  text-align: center;
  line-height: 30px;
  margin: 0;
}

/* .selected {
  background-color: #ccc;
  color: black;
} */


.math-quiz-container {
  background-color: #ffffff;
  height: 450px;
  border-top: 2px dashed black;
  border-bottom: 2px dashed black;
  overflow-y: auto;
  overflow-x: hidden;
}





.quest-number {
  /* margin-top: 20px; */
  margin-left: 10%;
}

.questions-number {
  background-color: black;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  display: inline-block;
  cursor: pointer;
  margin: 0;
}

.custom-tooltip-container {
  position: relative;
}

.custom-tooltip {
  position: absolute;
  background-color: white;
  border: 1px solid white;
  border-radius: 4px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.7);
  z-index: 999; 
  top: -45px; 
  width: 100%;
}

.custom-tooltip span {
  cursor: pointer;
}
.text-center {
  text-align: center; /* Center align the text */
  margin: auto; /* Center the element horizontally */
}

.fib-box {
  width: 20%;
  height: 50px;
  border-radius: 10px;
}

.calculator {
  margin-top: 20px;
  text-align: center;
}
.calculator p{
  font-size: 10px;
}

.calculator img{
  display: block;
  height: 20px;
  margin: 0 auto;
}

.mark_review {
  font-size: 12px;
}

.bottom_footer {
  background-color: #E6EDF8;
  position: fixed;
  z-index: 1000;
  bottom: 0;
  width: 100%;
  /* padding: '10px 20px', */
  border-top: 2px dashed black;
}

.padding-quiz {
  padding: 60px 0px 0px 60px;
}

.buttons-class {
  padding-right: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.answer-box > div {
  position: relative; /* Add this */
}

.review-icon {
  position: absolute;
  top: -6px;
  right: -5px;
  width: 12px;
  height: 12px;
}

.mcq-choice.selected {
  border: 1px solid #337ab7; 
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.7);
  border: 1px solid #E59700; 
}


.mcq-choice-letter.selected-letter {
  color: #ffffff; 
  background-color: #324DC7;
  font-size: 12px;
  font-weight: bold; 
}


.test-preparation {
  text-align: center;
  padding: 20px;
}

.test-preparation h2 {
  font-size: 24px;
  margin-bottom: 20px;
  color: #333;
}

.loading-section {
  display: inline-block;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: #fff;
}

.hourglass-img {
  width: 100px; /* Adjust the size as needed */
  margin-bottom: 20px;
}

.loading-section p {
  font-size: 16px;
  color: #666;
  max-width: 300px;
  margin: 0 auto;
}
