.component-sidebar {
    /* background-color: #48126A; */
    background-color: #48126A;
    max-width: 255px;
    padding: 15px;
    flex: 1 0 0%;
    position: fixed;
    width: 100%;
    height: 100%;
}

.component-sidebar-nav {
    height: calc(100% - 80px);
    /* overflow: auto; */
}
.component-sidebar-nav ul {
    list-style: none;
    padding: 0;
    padding-top: 60px;
    color: #ffffff !important;
}
.component-sidebar-nav ul li {
    padding: 10px 5px;
}
.component-sidebar-nav ul li a {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #ffffff;
    text-decoration-line: none;
    display: flex;
}
.component-sidebar-nav ul li a:hover {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    letter-spacing: 0.2px;
    color: #ffffff;
    text-decoration-line: none;
    display: flex;
}
.component-sidebar-nav ul li a .component-sidebar-nav-icon {
    min-width: 35px;
}
.component-sidebar-nav ul li a span {
    align-self: center !important;
}

.nav li.active {
    /* border-bottom: 3px solid #338ecf; */
  }

.activeStyle {
    background-color: #1C435F;
}