.courses-navbar {
    background-color: #F3F4F8;
    padding: 0;
    height: 70px;
    right: 0;
    z-index: 1001;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
  }
  
  .courses-navbar .navbar {
    height: 100%;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

.header-course {
    margin-top: 20px;

}

.course-navbar-header {
    align-self: center;
    text-align: center;
    justify-content: center;
}

.course-navbar {
    background-color: #F3F4F8;
    padding: 0;
    height: 70px;
    position: fixed;
    right: 0;
    left: 255px;
    z-index: 1001;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
}
  
.course-navbar2 {
    background-color: #F3F4F8;
    padding: 0;
    height: 70px;
    position: fixed;
    right: 0;
    left: 80px;
    z-index: 1001;
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
}
  
.course-navbar-brand {
    min-width: 255px;
    height: 100%;
    padding: 0 15px;
}
  
.user-image {
    width: 35px;
    height: 35px;
}
  
.user-image img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
    margin-top: -7px;
}
  
.navbar-nav .nav-item-down .dropdown-menu {
    right: 0;
    left: auto;
}

li.nav-item-down.dropdown {
    margin-top: 0px;
}

.home_link {
    margin-top: -8px;
    margin-right: -37px;
}