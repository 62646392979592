.course-card {
  /* width: 18rem; */
  height: 250px;
  background: #fbd65a;
  border-radius: 10px;
}

.course-card2 {
  /* width: 18rem; */
  height: 250px;
  background: #a578f7;
  border-radius: 10px;
}

.course-card3 {
  /* width: 18rem; */
  height: 250px;
  background: #ef6ab7;
  border-radius: 10px;
}

.card-title {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  text-align: justify;
  color: #ffffff;
  margin-right: 5%;
}
.card-text {
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: justify;
  color: #333;
  margin-right: 5%;
}
progress {
  accent-color: #7be379;
}

.link-class {
  text-decoration: none;
}

.loading-message {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 24px;
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #888;
  animation: spin 1s linear infinite;
  border-radius: 50%;
  width: 50px;
  height: 50px;
}

.center-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 75vh;
  text-align: center;
}

.center-page p {
  margin: 10px 0;
}


@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}


/* New Card Design CSS */

.card-container {
  width: 300px;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  font-family: Arial, sans-serif;
}

.card-banner {
  height: 150px;
  background-image: url("../../../public/assets/java.jpg");
  background-size: cover;
  background-position: center;
  position: relative;
}

.badge {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: gold;
  color: black;
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 5px;
  font-weight: bold;
}

.card-content {
  padding: 15px;
}

.category {
  color: gray;
  font-size: 12px;
  text-transform: uppercase;
  margin: 0;
}

.title {
  font-size: 18px;
  font-weight: bold;
  margin: 5px 0 10px;
  text-align: left; 
  color: #333 !important;
}

.reviews {
  /* display: flex; */
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
}

.rating {
  margin-right: 5px;
  font-weight: bold;
}

.stars {
  color: #f5b301;
  margin-right: 5px;
}

.review-count {
  color: gray;
}

.avatars {
  display: flex;
  margin-bottom: 10px;
}

.avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: -10px;
  border: 2px solid #fff;
}

.pricing {
  font-size: 16px;
  margin-bottom: 10px;
}

.old-price {
  text-decoration: line-through;
  color: gray;
  margin-right: 5px;
}

.current-price {
  color: #e63946;
  font-weight: bold;
}

.lesson-info {
  font-size: 14px;
  color: gray;
  text-align: right;
}
