.ReactModal__Overlay .ReactModal__Overlay--after-open{
    position: fixed;
    inset: 0px;
    background-color: rgba(46, 44, 44, 0.75);
    margin-top: 50px;
    padding-top: 100px;
}
.close_icon {
    width: 25px;
    height: 25px;
    /* filter: invert(12%) sepia(29%) saturate(6910%) hue-rotate(267deg) brightness(90%) contrast(103%); */
    filter: invert(53%) sepia(94%) saturate(5446%) hue-rotate(346deg) brightness(101%) contrast(82%);
}

.close_icon:hover{
    width: 25px;
    height: 25px;
    filter: invert(51%) sepia(94%) saturate(5865%) hue-rotate(356deg) brightness(100%) contrast(102%);
}
.close-btn {
    background-color: transparent;
    border: none;
}

.btn-enroll {
    background-color: #E84E36;
    width: 100px;
    color: white;
}

.btn-enroll:hover {
    background-color: #FF4008;
    color: white;
}

.btn-outline-cancel {
    border: 1px solid #E84E36;
    color: #E84E36;
    width: 100px;
}
  
.btn-outline-cancel:hover {
    background-color: #FF4008;
    color: white; 
}
  

.subject-title {
    color: black;
}

.payment-modal .steps-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .payment-modal .step {
    text-align: center;
  }
  
  .payment-modal .circle {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: transparent;
    border: 2px solid #E84E36;
    color: #E84E36;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 5px auto;
  }
  
  .payment-modal .step.active .circle {
    background-color: #E84E36;
    color: white;
  }
  
  .payment-modal .dotted-line {
    flex-grow: 1;
    border-top: 2px dotted #E84E36;
    height: 0;
    margin: 0 10px;
  }
  
  .payment-modal .close-btn {
    background: none;
    border: none;
  }
  
  .payment-modal .close-btn img {
    width: 20px;
    height: 20px;
  }
  
  .payment-modal .course-overview ul {
    padding-left: 20px;
  }
  
  .payment-modal .subject-title {
    margin-top: 20px;
  }
  
  .payment-modal .mt-3 {
    margin-top: 20px;
  }
  