.banner {
  /* height: 100vh; */
  background-color: #f3fafc;
}
.banner .content {
  padding-top: 200px;
}
.title-main {
  font-size: 20px;
  line-height: 25px;
  font-weight: 700;
  color: #0c8b51;
  text-transform: uppercase;
  padding-bottom: 10px;
}
.sub-title {
  font-size: 38px;
  line-height: 1.5;
  font-weight: 700 !important;
  color: #171f32;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.banner .img {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  min-height: 400px;
  margin-top: 150px;
  margin-bottom: 110px;
}

.spine2 {
  animation: spine 8s linear infinite;
  -webkit-animation: spine 8s linear infinite;
}
@keyframes spine2 {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}
@-webkit-keyframes spine2 {
  from {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  from {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

.up-down {
  animation: up-down 4s alternate infinite;
  -webkit-animation: up-down 4s alternate infinite;
}
.up-down-new {
  animation: up-down 8s alternate infinite;
  -webkit-animation: up-down 8s alternate infinite;
}

@keyframes up-down {
  0% {
    transform: translateY(30px);
    -webkit-transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}
@-webkit-keyframes up-down {
  0% {
    transform: translateY(30px);
    -webkit-transform: translateY(0);
  }
  50% {
    transform: translateY(-50px);
    -webkit-transform: translateY(-50px);
  }
  100% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
  }
}

@media (max-width: 1024px) {
  .banner .content {
    padding-left: 0;
    padding-right: 0;
  }
}
@media (max-width: 992px) {
  .banner .img {
    min-height: 370px;
  }
  .banner .content {
    padding-top: 0;
    padding-bottom: 30px;
    text-align: center;
  }
}
@media (max-width: 575px) {
  .banner .img {
    min-height: 300px;
  }
  .banner .content {
    padding-top: 0;
    padding-bottom: 30px;
    text-align: center;
  }
}

.banner-intro-box .intro-img {
  position: absolute;
  right: 8%;
  top: 60px;
}
.banner-intro-box .intro-img img {
  width: 600px;
}
