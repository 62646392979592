.success-stories {
    background-color: #f3fafc;
}


.right-card {
    background-color: #94B4BF;
    border-radius: 10px;
    height: 350px;
}
.success-cards-img {
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 10px 10px;
}

.imgage-background {
  background-color: #A34495;
  width: 110px;
  height: 110px;
  border-radius: 10px;
}

.l-card {
    background: #ffffff;
    padding: 6px 10px;;
    border-radius: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
  }
.l-card img {
    padding: 0 20px;
    width: 110px;
    height: 110px;
  }



 