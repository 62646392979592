.answer-correct-box {
    background-color: green;
    padding: 8px;
    color: #fff;
}

.answer-wrong-box {
    background-color: red;
    padding: 8px;
    color: #fff;
}

.answer-unattempted-box {
  border: 1px dashed #333;
  padding: 8px;
  }

.result-answer-box {
    display: flex;
    flex-wrap: wrap; 
    justify-content: flex-start; 
    padding-bottom: 10px;
  }
  