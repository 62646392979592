.console {
    border-left: 1px solid #000000;
}
.console_body {
    /* border-left: 1px solid #000000; */
    background-color: #666666;
    height: 39.5vh;
}
.console_tab {
    border-bottom: 1px solid rgb(126, 136, 145);
    display: flex;
    white-space: nowrap;
    border-top-color: rgb(126, 136, 145);
    border-right-color: rgb(126, 136, 145);
    border-left-color: rgb(126, 136, 145);
    background-color: #f3f3f3;
}

.tab_button {
    -webkit-box-align: center;
    align-items: center;
    border-right: 1px solid rgb(126, 136, 145);
    cursor: default;
    /* display: flex; */
    font-size: 14px;
    height: 31px;
    padding: 2px 16px 3px;
    white-space: nowrap;
}
.down_button {
    min-height: 30px;
    width: 39px;
}
.icon_down_button {
    background-color: transparent;
    border: none;
}