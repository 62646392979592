.course-heading {
  text-align: center;
  font-weight: 800;
  font-size: 36px;
}
.courses-card {
  /* border: 1px solid black; */
  border-radius: 10px 10px 0px 0px;
  width: 20rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1); /* Light shadow */
  transition: box-shadow 0.3s ease; /* Smooth transition for hover effect */
  color: #999999;
  border-radius: 10px;
}

.courses-card:hover {
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}

.colgap {
  column-gap: 100px !important;
}

.courses-C {
  row-gap: 1ch;
}

.card-main-title {
  font-size: 20px;
  line-height: 36px;
  font-weight: 700;
  padding-bottom: 5px;
  margin: 0;
  color: #171f32;
}
.card-img-top {
  width: 100%;
  height: 170px;
  border-radius: 10px 10px 0px 0px;
}

.courses-card p {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: justify;
  color: black;
}
.courses-card .card-foter-content {
  margin-bottom: 0;
  text-decoration: none;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: justify;
  color: #000000;
}

.star-icon {
  width: 20px;
  height: 20px;
  margin-bottom: 8px;
  filter: invert(100%) sepia(40%) saturate(3399%) hue-rotate(324deg)
    brightness(97%) contrast(103%);
}

.courses-card .card-title {
  color: #000000;
  font-size: 16px;
  font-weight: 700;
}

.starting-price {
  color: #74767e;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  display: inline-block;
  vertical-align: top;
  padding: 5px 0 0;
}

.favorite {
  width: 20px;
  height: 20px;
  filter: invert(84%) sepia(10%) saturate(79%) hue-rotate(190deg)
    brightness(87%) contrast(85%);
}

.reviews {
  color: #000000;
  font-weight: 500;
}

.card-title-container {
  word-wrap: break-word;
}

.course-button {
  font-size: 16px;
  display: inline-block;
  padding: 10px 35px 10px 35px;
  border-radius: 10px;
  transition: 0.5s;
  color: #0c8b51;
  font-weight: 700;
  background: #f3fafc;
  border: 2px solid #f3fafc;
  text-decoration: none;
}

.course-button:hover {
  border-color: #0c8b51;
  background: #0c8b51;
  color: white;
}

@media (max-width: 1024px) {
  .colgap {
    column-gap: 60px;
  }
}

@media (max-width: 992px) {
  .courses-card {
    border: 1px solid black;
    border-radius: 10px 10px 0px 0px;
  }
  .colgap {
    column-gap: 0;
  }
}

@media (max-width: 575px) {
  .colgap {
    column-gap: 0;
  }
}
