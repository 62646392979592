.fibquiz-container {
    background-color: #ffffff;
    height: 400px;
    width: 800px;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.25);
    box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25), -2px -2px 0px rgba(0, 0, 0, 0.25);
}

.fibquiz_video_frame {
    height: 398px;
    width: 798px;
    border-radius: 10px;
}
