.score-card {
    border: 1px solid #ccc;
    padding: 20px;
    text-align: center;
    width: 300px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.course-title {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.total-score {
    font-size: 1.2em;
    margin-bottom: 20px;
}

.buttons-container {
    display: flex;
    flex-direction: column;
}

.view-details-button {
    padding: 10px;
    font-size: 1em;
    margin-bottom: 10px; 
    border-radius: 20px;
    border: none;
    cursor: pointer;
    background-color: #3498db;
    color: #fff; 
}

.retake-button:hover,
.view-details-button:hover {
    background-color: #2980b9;
    color: #fff;
}

.retake-button {
    background-color: transparent;
    border: 1px solid #2980b9;
    color: #2980b9;
    cursor: pointer;
    padding: 10px;
    font-size: 1em;
    margin-bottom: 10px; 
    border-radius: 20px;
}