.contact-cards {
    background-color: #f9f9f9;
    /* border-radius: 10px; */
    border: none;
    margin-top: 20%;
  }
  
  .contact-icons {
    width: 60px;
    height: 60px;
  }
  
  h5 {
    margin: 0;
  }
  
  p {
    margin: 0;
    font-size: 1.2em;
  }
  