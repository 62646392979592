.profile-info {
  background: #ffffff;
  box-shadow: 2px 2px 0px rgba(0, 0, 0, 0.25), -2px -2px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  height: auto;
}

.edit_icon {
  filter: invert(12%) sepia(29%) saturate(6910%) hue-rotate(267deg)
    brightness(90%) contrast(103%);
  height: 25px;
  width: 25px;
}

.student_Image {
  width: 150px;
  height: 150px;
  border-radius: 100px;
}

.form_label {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: justify;
  color: #000000;
}
