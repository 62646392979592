.hamburger{
	font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
	cursor: pointer;
    text-align: right;
    color: #ffffff;
    padding-right: 20px;
}

.hamburger2{
	font-size: 20px;
    font-weight: 500;
    margin-top: 10px;
	cursor: pointer;
	color: #ffffff;
    text-align: center;

}

.check {
    margin-left: 34px;
}
.pro-sidebar {
    color: #ffffff; 
    height: 100%;
    width: 255px;
    min-width: 255px;
    text-align: left;
    transition: width, left, right, 0.3s;
    position: fixed;
    z-index: 1009;
}

.pro-sidebar > .pro-sidebar-inner{
    background-color: #48126A;
    height: 100vh;
    /* position: fixed;
    width: 255px; */
}
.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout .pro-sidebar-header {
   border: none;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item{
    color: white;
    font-size: 14px;
    font-weight: 600;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
    background-color: #48126A;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    border-color: rgb(0, 0, 0);
}

.pro-sidebar .pro-menu .pro-menu-item:hover{
    color: rgb(0, 0, 0);
}

.pro-sidebar.collapsed .pro-menu > ul > .pro-menu-item.pro-sub-menu > .pro-inner-list-item > .popper-inner {
    max-height: 100vh;
    overflow-y: auto;
	background-color: #48126A;
    padding-left: 20px;
    border-radius: 4px;
}

.pro-sidebar-footer{
	text-align: center;
}

.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: transparent;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item > div > ul {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}


.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 35px 8px 20px;
    cursor: pointer;
    color: #ffffff !important;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu > .pro-inner-item > .pro-arrow-wrapper .pro-arrow {
    border-color: #ffffff
}

.menu-icon {
    width: 16px;
    height: 16px;
    filter: brightness(0) invert(1); /* Makes the image white */
  }
  
  .sidebar.collapsed .pro-menu-item {
    text-align: center;
    margin-left: 10px;
  }
  /* .sidebar.collapsed .menu-icon {
    margin: 0 auto; 
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
   */