.component-navbar {
  background-color: #F3F4F8;
  padding: 0;
  height: 70px;
  position: fixed;
  right: 0;
  left: 255px;
  z-index: 1001;
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
}

.component-navbar .navbar {
  height: 100%;
  padding: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  margin-right: 55px;
}

.component-navbar-brand {
  background-color: transparent;
  min-width: 255px;
  height: 100%;
  padding: 0 15px;
  margin-right: 30px;
  display: flex;
  color: #000000;
}

.course-navbar-header-logo {
  width: 130px;
  color: white;
  align-self: center;
  text-align: center;
  justify-content: center;

}

.navbar-nav img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
}

.nav-link {
  color: rgb(0, 0, 0) !important;
}

.component-navbar-user-image {
  width: 50px;
  height: 50px;
  position: relative;
  /* margin-right: 30px; */
}

.component-navbar-user-image img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.navbar-nav .nav-item .dropdown-menu {
  right: 0;
  left: auto;
}

li.nav-item.dropdown {
  margin-top: 12px;
}
