.goal-image {
  width: 70px;
  height: 90px;
  margin-left: auto;
  margin-right: auto;
}

.goal-heading {
  font-weight: 800;
  font-size: 36px;
  text-align: center;
}

.sub-heading {
  margin-bottom: 7px;
    font-size: 36px;
    font-weight: 700;
    color: #111;
}