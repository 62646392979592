.course-overview-headings {
  font-weight: 800;
  font-size: 28px;
}

.reviewer-name {
  font-weight: 800;
  font-size: 18px;
}
.profileSection {
    background-color: white;
    border-radius: 10px;
    padding: 15px;
    border: 1px solid rgb(206, 204, 204);
  }
  

  .reviewImg {
    border-radius: 50%;
    height: 50px;
    width: 50px;
  }
  .included_list {
    list-style: none;
  }

  .tick_icon {
    width: 20px;
    height: 20px;
  }

  .review_para {
    margin-left: 24px;
    font-size: 14px !important;
  }
  
  .reviewdate {
    font-size: 14px;
  }

.accordion-button:not(.collapsed) {
    color: #0c8b51;
    background-color: #f3fafc;
    box-shadow: inset 0 -1px 0 rgb(0 0 0 / 13%);
}

  @media (max-width: 768px) {
    .profileimg {
      height: 280px;
    }
  }
  
  /* enrollment css */

  .enrollment {
    background-color: white;
    height: auto;
    border-radius: 10px;
    padding: 15px;
    border: 1px solid rgb(206, 204, 204);
  }
  .app-head {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    justify-content: left;
  }
  /* .enroll_btn {
    width: 100%;
    height: 40px;
    background: #ffffff;
    border: 1px solid #E84E36;
    font-size: 16px;
    line-height: 30px;
    color: #E84E36;
    border-radius: 10px;
  } */

  .enroll_btn {
    display: flex; /* Enables flexbox for alignment */
    justify-content: space-between; /* Positions content at opposite ends */
    align-items: center; /* Vertically centers content */
    width: 100%; /* Adjust width as needed */
    padding: 10px 20px;
    background: #ffffff;
    border: 2px solid #e84e36;
    color: #e84e36;
    font-size: 16px;
    font-weight: 600;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .enroll_btn:hover {
    width: 100%;
    height: 40px;
    background: #FF4008;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    border-radius: 10px;
  }

  .button-text {
    font-weight: 600;
    font-size: 16px;
  }
  
  .button-price {
    font-weight: 700;
    font-size: 16px;
  } 

  
  .help_btn {
    width: 100%;
    height: 40px;
    background: #E84E36;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    border: 1px solid #E84E36;
    border-radius: 10px;
  }
  
  .help_btn:hover {
    width: 100%;
    height: 40px;
    background: #FF4008;
    font-size: 16px;
    line-height: 30px;
    color: #ffffff;
    border: 1px solid #FF4008;
    border-radius: 10px;
  }
  
  .enrollment ul {
    list-style: none;
  }
  .enroll-img {
    width: 18px;
    height: 18px;
    filter: invert(53%) sepia(94%) saturate(5446%) hue-rotate(346deg) brightness(101%) contrast(82%);
  }