.correct-answer-is {
  color: white;
  display: inline-block;
}
.answer-is {
  margin-top: none !important;
  margin-bottom: none !important;
  background-color: #0c8b51;
  padding: 10px;
  border-radius: 5px;
}

.correct-answer {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #0c8b51 !important;
  color: #0c8b51;
  cursor: pointer;
}

.wrong-answer {
  display: flex;
  align-items: center;
  border-radius: 10px;
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid red !important;
  color: red;
  cursor: pointer;
}

.explaination-text {
  text-align: justify;
}

/* .correct-answer span {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: transparent;
  color: #0c8b51;
  text-align: center;
  line-height: 24px;
  margin-right: 15px;
  border: 1px solid #0c8b51;
} */

/* .wrong-answer span {
  display: inline-block;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: transparent;
  color: red;
  text-align: center;
  line-height: 24px;
  margin-right: 15px;
  border: 1px solid red;
} */
