
/* ModalBackdrop styles */
.modalBackdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999;
  }
  
  /* ModalContent styles */
  .modalContent {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1000;
  }
  
  /* PulseLoader override styles */
  .pulseLoaderOverride {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: block;
    margin: 0 auto;
    border-color: red;
    z-index: 1000;
  }
  