.why-us {
  text-align: center;
  font-weight: 800;
  font-size: 36px;
}

.sub-paragrapgh {
  font-size: 18px;
  line-height: 30px;
  color: #505050;
}

.card {
  padding-top: 20px;
  border: none;
}
.card-p {
  color: #333;
  font-size: 16px !important;
}


.card-title {
  font-size: 20px;
  line-height: 36px;
  font-weight: 700;
  padding-bottom: 5px;
  margin: 0;
  color: #171f32;
  text-align: center;
}
.card-icons {
  width: 70px;
  height: 70px;
  margin-left: auto;
  margin-right: auto;
}
.counting {
  align-self: center;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: 700;
  font-size: 32px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #011f4b;
  color: white;
}

@media (max-width: 575px) {
  .sub-paragrapgh {
    text-align: justify;
    font-size: 18px;
    line-height: 28px;
    /* color: #000000; */
    padding-left: 0;
    padding-right: 0;
  }
}
