.title-content {
    font-weight: 700;
    font-size: 32px !important;
    line-height: 52px;
    color: #0c8b51;
}

.proven-paragraph {
    font-size: 20px !important;
    line-height: 1.2;
    font-weight: 400;
    margin: 0;
    color: #333;
}
/* .content-p {
    font-size: 32px !important;
} */
.proven-result .content {
    margin-top: 180px;
}
.proven-result-img {
    height: 440px;
    width: 370px;
}

.proven-result li {
   list-style: none;
}

.proven-list {
   margin-top: 70px;
}

@media (max-width: 1024px) {
    .proven-result-img {
        display: none;
    }
  }
  @media (max-width: 992px) {
    .proven-result-img {
        display: none;
    }
    .proven-result .content {
        margin-top: 100px;
    }
    .proven-list {
        margin-top: 20px;
     }
  }
  @media (max-width: 575px) {
    .proven-result-img {
        display: none;
    }
    .proven-result .content {
        margin-top: 0;
        text-align: center;
    }
    .proven-list {
        margin-top: 30px;
     }
  }
  
