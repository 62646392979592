.appointment {
  background-color: #f3fafc;
  border-radius: 30px;
}
.text-heading {
  color: #0c8b51;
  text-align: center;
  font-weight: 800;
  font-size: 36px;
}
.para {
  color: #0c8b51;
}
.appointment-input {
  text-align: center;
}
.appointment-form-input {
  background: white;
  border-radius: 10px 0px 0px 10px;
  border: 1px solid #E84E36;
  height: 40px;
  
}

.btn-submit {
  background: #E84E36;
  border-radius: 0px 10px 10px 0px;
  text-decoration: none;
  color: white !important;
  height: 40px;
}

@media (max-width: 575px) {
  .appointment {
    background-color: #f3fafc;
    border-radius: 0;
  }
}