.deliver {
    background-color: #f3fafc;
  }
  
  .deliver-heading {
    font-weight: 800;
    font-size: 36px;
  }
  
  .deliver-paragraph {
    font-size: 18px;
    line-height: 30px;
  }

  .deliver .img {
    /* background-size: contain; */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    min-height: 600px;
  }
  
  @media (max-width: 1024px) {
    .deliver .content {
      padding-left: 0;
      padding-right: 0;
    }
  }
  @media (max-width: 992px) {
    .deliver .img {
      min-height: 300px;
      margin-top: 35px;
    }
    .deliver .content {
      margin-bottom: 20px;
      text-align: center;
    }
  }
  @media (max-width: 575px) {
    .deliver .img {
      min-height: 200px;
      margin-top: 30px;
    }
    .deliver .content {
      margin-top: 20px;
      margin-bottom: 20px;
      text-align: center;
    }
  }
  